<template>
	<div class="loader-wrapper" id="loader-wrapper" style="display: none;">
        <div id="loading-bar-spinner" class="frontend spinner">
            <!-- <div class="spinner-icon"></div> -->
            <img alt="Apolide logo" src="../assets/images/orange-logo.png">
        </div>
    </div>
	<section class="newsletter-section cornsilk-bg">
		<div class="custom-container d-flex justify-content-between align-items-center">
			<h2 class="title mb-0">Subscribe to Our Newsletter</h2>
			<form action="">
				<div class="d-flex">
					<input type="mail" id="email" class="form-control cornsilk-bg" placeholder="Your Email Address" v-model="post.email">
					<button type="submit" class="common-btn" @click.stop.prevent="submitData()">Subscribe</button>
				</div>
				<p class=" error-msg" v-if="errors.email">{{ errors.email }}</p>
			</form>
		</div>
	</section>
	<footer>
		<div class="main-footer">
			<div class="custom-container text-center">
				<div class="logo d-flex flex-column align-items-center">
					<router-link :to="{ name: 'HomePage'}"><img src="../assets/images/dark-logo.svg"></router-link>
					<a href="mailto:inquiries@apolidetravel.com">inquiries@apolidetravel.com</a>
				</div>
				<ul class="d-flex justify-content-center align-items-center">
					<li v-if="guideTypeSlug">
						<router-link :to="{ name: 'TypeWiseGuidePage', params: { guideSlug: guideTypeSlug || 'default' }}">
							Guides
						</router-link>
					</li>
					<li><router-link :to="{ name: 'ServicesPage'}">Services</router-link></li>
					<li><router-link :to="{ name: 'AboutPage'}">About</router-link></li>
					<li><router-link :to="{ name: 'InquirePage'}">Inquire</router-link></li>
				</ul>
			</div>
		</div>
		<div class="bottom-footer">
			<div class="custom-container d-flex justify-content-between align-items-center">
				<p>© {{currentYear}} Apolide Travel, All Rights Reserved</p>
				<!-- <a href="https://wvelabs.com/" target="_blank"><img src="../assets/images/wve-icon.png" alt="Design & Develop by Wve Labs"></a> -->
				<ul class="social d-flex align-items-center">
					<li><a href="https://www.instagram.com/apolidetravel/" target="_blank"><img src="../assets/images/icons/instagram.svg"></a></li>
					<li><a href="https://www.linkedin.com/company/apolide-travel/" target="_blank"><img src="../assets/images/icons/linkedin.svg"></a></li>
				</ul>
				<ul class="docs d-flex align-items-center">
					<li><router-link :to="{ name: 'TermsConditionsPage'}">Terms & Conditions</router-link></li>
					<li><router-link :to="{ name: 'PrivacyPolicyPage'}">Privacy Policy</router-link></li>
				</ul>
				<a class="wve-icon" href="http://wvelabs.com" target="_blank" title="Designed & Developed by Wve Labs">
					<img src="../assets/images/wve-icon.svg" alt="Designed & Developed by Wve Labs">
				</a>
			</div>
		</div>
  </footer>
</template>

<script>
import axios from 'axios';
import { showLoader, hideLoader } from '../views/utils/loaderUtils';

export default {
	name: 'MainFooter',
	created() {
		this.getActiveGuideTypeSlug();
	},
	data() {
		return {
			currentYear: new Date().getFullYear(), // 2020
			guideTypeSlug: '',
			post: {
				email: ''
			},
			errors:{
				email: ''
			}
		};
	},
	// mounted () {
	// 	window.scrollTo({
	// 		top: 0,
	// 		behavior: 'smooth'
	// 	});
	// },
	methods: {
		// resetScrollPosition() {
        //     // Wait for DOM updates and then scroll to the top
        //     this.$nextTick(() => {
        //         window.scrollTo({
        //             top: 0,
        //             behavior: 'smooth'
        //         });
        //     });
        // },

		async getActiveGuideTypeSlug() {
			try {
				const response = await axios.get('/api/user/v1/active-guide-type');
				this.guideTypeSlug = response.data.data && response.data.data[0] ? response.data.data[0].slug : '';
			} catch (error) {
				console.error(error);
			}
		},
		async submitData() {
			try {
				// Clear previous errors
				this.errors = {
                    email: "",
                };

                let isValid = true;

				if (!this.post.email) {
					this.errors.email = 'Please enter email address';
					isValid = false;

				}
				if (!this.validateEmail(this.post.email)) {
					this.errors.email = 'Please enter a valid email address';
					isValid = false;
				}

				// Stop if there are validation errors
				if (!isValid) {
                    return;
                }

				// Proceed with API call
				let formData = new FormData();
                formData.append("email", this.post.email);
                showLoader();

                axios.post(`/api/user/v1/subscribe-user`, formData, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					if (response.data && response.data.success == true) {
						hideLoader();

						this.post.email = '';

						this.$toast.success(response.data.message, {
							position: "top-right",
							duration: 3000,
						});
					} else {
						hideLoader();
						this.$toast.error(response.data.message, {
							position: "top-right",
							duration: 3000,
						});
					}
				})
				.catch((error) => {
					hideLoader();
					this.$toast.error(error.response.data.message, {
						position: "top-right",
						duration: 3000,
					});
				});
			} catch (error) {
				console.error(error);
			}
		},
		validateEmail(email) {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			return emailRegex.test(email);
		}
	}
}
</script>