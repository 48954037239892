import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/admin/AdminLayout.vue'
import store from '../store'
import axios from "axios";
import { useToast } from "vue-toast-notification";

const $toast = useToast();
const routes = [
	// Auth Routes
	{
		path: '/auth',
        name: "Auth",
		meta: { layout: DefaultLayout },
		children:[
			{
				path: 'sign-up',
				name: 'SignUp',
				component: () => import('@/views/SignUp'),
			},
			{
				path: 'sign-in',
				name: 'SignIn',
				component: () => import('@/views/SignIn'),
			},
			{
				path: 'forgot-password',
				name: 'UserForgotPassword',
				component: () => import('@/views/ForgotPassword'),
			},
			{
				path: 'choose-password',
				name: 'ChoosePassword',
				component: () => import('@/views/ChoosePassword'),
			},
			{
				path: 'enter-otp',
				name: 'EnterOtp',
				component: () => import('@/views/EnterOtp'),
			},
		]
	},

	// User Routes	
	{
		path: '/',
		name: 'HomePage',
		component: () => import('@/views/HomePage'),
		meta: { layout: DefaultLayout },
	},
	{
		path: '/guide/:guideSlug/:citySlug',
		name: 'GuidePage',
		component: () => import('@/views/GuidePage'),
		meta: { layout: DefaultLayout },
	},
	{
		path: '/guide/:guideSlug',
		name: 'TypeWiseGuidePage',
		component: () => import('@/views/TypeWiseGuidePage'),
		meta: { layout: DefaultLayout },
	},

	{
		path: '/payment/:guideSlug/:citySlug',
		name: 'PaymentPage',
		component: () => import('@/views/PaymentPage'),
		meta: {
			requiresUserAuth: true,
		},
	},
	{
		path: '/payment/confirmation',
		name: 'PaymentConfirmation',
		component: () => import('@/views/PaymentConfirmation'),
		meta: {
			//requiresUserAuth: true,
		},
	},
	{
		path: '/about',
		name: 'AboutPage',
		component: () => import('@/views/AboutPage'),
		meta: { layout: DefaultLayout },
	},
	{
		path: '/terms-and-conditions',
		name: 'TermsConditionsPage',
		component: () => import('@/views/TermsConditionsPage'),
		meta: { layout: DefaultLayout },
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicyPage',
		component: () => import('@/views/PrivacyPolicyPage'),
		meta: { layout: DefaultLayout },
	},
	{
		path: '/services',
		name: 'ServicesPage',
		component: () => import('@/views/ServicesPage'),
		meta: { layout: DefaultLayout },

	},
	{
		path: '/inquire',
		name: 'InquirePage',
		component: () => import('@/views/InquirePage'),
		meta: { layout: DefaultLayout },

	},
	{
		path: '/my-library',
		name: 'LibraryPage',
		component: () => import('@/views/LibraryPage'),
		meta: {
			layout: DefaultLayout,
			requiresUserAuth: true,
		},
	},
	{
		path: '/edit-profile',
		name: 'EditProfile',
		component: () => import('@/views/EditProfile'),
		meta: {
			requiresUserAuth: true,
		},
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		name: 'Admin',
		meta: { layout: AdminLayout },
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/admin/auth/Login'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset',
				name: 'ForgotPassword',
				component: () => import('@/views/admin/auth/ForgotPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'ResetPassword',
				component: () => import('@/views/admin/auth/ResetPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Apolide',
					metaTags: [{name: 'description',content: 'The dashboard page of our Apolide.'},{property: 'og:description',content: 'The dashboard page of our Apolide.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: '403',
				name: '403',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Apolide',
					metaTags: [{name: 'description',content: 'The dashboard page of our Apolide.'},{property: 'og:description',content: 'The dashboard page of our Apolide.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'profile-update',
				name: 'Profile Update',
				component: () => import('@/views/admin/ProfileUpdate'),
				meta: {
					title: 'Dashboard - profile-update',
					metaTags: [{name: 'description',content: 'The login user profile update page of our Apolide.'},{property: 'og:description',content: 'The dashboard page of our Apolide.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'change-password',
				name: 'Change Password',
				component: () => import('@/views/admin/ChangePassword'),
				meta: {
					title: 'Dashboard - Apolide',
					metaTags: [{name: 'description',content: 'The dashboard page of our Apolide.'},{property: 'og:description',content: 'The dashboard page of our Apolide.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: "user",
				name: "User",
				redirect: '/admin/user/list',
				children: [
					{
						path: "list",
						name: "User List",
						component: () => import('@/views/admin/user/List'),
						meta: {
							title: 'User Management - Apolide',
							metaTags: [{name: 'description',content: 'The user Management page of our Apolide.'},{property: 'og:description',content: 'The user Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "User Create",
						component: () => import('@/views/admin/user/Create'),
						meta: {
							title: 'User Management - Apolide',
							metaTags: [{name: 'description',content: 'The user Management page of our Apolide.'},{property: 'og:description',content: 'The user Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "User Edit",
						component: () => import('@/views/admin/user/Edit'),
						meta: {
							title: 'User Management - Apolide',
							metaTags: [{name: 'description',content: 'The User Management page of our Apolide.'},{property: 'og:description',content: 'The User Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "guide",
				name: "Guide",
				redirect: '/admin/guide/list',
				children: [
					{
						path: "list",
						name: "Guide List",
						component: () => import('@/views/admin/guide/List'),
						meta: {
							title: 'Guide Management - Apolide',
							metaTags: [{name: 'description',content: 'The Guide Management page of our Apolide.'},{property: 'og:description',content: 'The Guide Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Guide Create",
						component: () => import('@/views/admin/guide/Create'),
						meta: {
							title: 'Guide Management - Apolide',
							metaTags: [{name: 'description',content: 'The Guide Management page of our Apolide.'},{property: 'og:description',content: 'The Guide Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Guide Edit",
						component: () => import('@/views/admin/guide/Edit'),
						meta: {
							title: 'Guide Management - Apolide',
							metaTags: [{name: 'description',content: 'The Guide Management page of our Apolide.'},{property: 'og:description',content: 'The Guide Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
				]
			},
			{
				path: "inquiry",
				name: "Inquiry",
				redirect: '/admin/inquiry/list',
				children: [
					{
						path: "list",
						name: "Inquiry List",
						component: () => import('@/views/admin/inquiry/List'),
						meta: {
							title: 'Inquiry Management - Apolide',
							metaTags: [{name: 'description',content: 'The Inquiry Management page of our Apolide.'},{property: 'og:description',content: 'The Inquiry Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "view/:id",
						name: "Inquiry View",
						component: () => import('@/views/admin/inquiry/View'),
						meta: {
							title: 'Inquiry Management - Apolide',
							metaTags: [{name: 'description',content: 'The Inquiry Management page of our Apolide.'},{property: 'og:description',content: 'The Inquiry Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
				]
			},
			{
				// Newsletter
				path: "newsletter",
				name: "Newsletter",
				redirect: '/admin/newsletter/list',
				children: [
					{
						path: "list",
						name: "Newsletter List",
						component: () => import('@/views/admin/newsletter/List'),
						meta: {
							title: 'Newsletter Management - Apolide',
							metaTags: [{name: 'description',content: 'The Newsletter Management page of our Apolide.'},{property: 'og:description',content: 'The Newsletter Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
				]
			},
			{
				// Transaction History
				path: "transaction-history",
				name: "Transaction History",
				redirect: '/admin/transaction-history/list',
				children: [
					{
						path: "list",
						name: "Transaction History List",
						component: () => import('@/views/admin/transaction-history/List'),
						meta: {
							title: 'Transaction History Management - Apolide',
							metaTags: [{name: 'description',content: 'The Transaction History Management page of our Apolide.'},{property: 'og:description',content: 'The Transaction History Management page of our Apolide.'}],
							requiresAdminAuth: true,
						}
					},
				]
			}
		],
	}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            // Let the component handle the hash scroll
            return false;
        }
        // Default behavior: scroll to the top
        return { top: 0 };
    },
})

async function validateToken() {
    try {
		const isUserLoggedIn = store.getters.isUserLoggedIn;

		if (isUserLoggedIn) {
			const token = store.getters.currentUser.token; 
			axios.defaults.headers.common["Authorization"] = "Bearer "+token;
		}
		await axios.get("/api/user/validate-token");
        return true; // If the token is valid
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized is returned, the token is invalid
            $toast.error(error.response.data.message, {
                position: 'top-right',
               duration: 3000,
            });
            return false;
        }
        return false;
    }
}

router.beforeEach(async (to, from, next) => {
	// Set the title based on route meta
	// document.title = to.meta.title || 'Apolide Travel'; // Default title if not set

	// Set the meta tags
	const metaTags = to.meta.metaTags;
	if (metaTags) {
		metaTags.forEach(tag => {
			const metaTag = document.createElement("meta");

			// Check if the tag has a 'name' or 'property' attribute
			if (tag.name) {
				metaTag.setAttribute("name", tag.name);
			}
			if (tag.property) {
				metaTag.setAttribute("property", tag.property);
			}

			// Set the content attribute
			if (tag.content) {
				metaTag.setAttribute("content", tag.content);
			}
			document.head.appendChild(metaTag);
		});
	}

	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || 'DefaultLayout';
	if (layout === 'DefaultLayout' || layout === 'AdminLayout') {
		// Check if the Vue instance is available
		if (router.app) {
			router.app.$options.components.Layout = layout === 'AdminLayout' ? AdminLayout : DefaultLayout;
		} else {
			// console.error('Vue instance not available.');
		}
	}

	// Manage CSS files dynamically based on the route
	const isAdminRoute = to.path.startsWith('/admin');

	// Remove CSS files if it's an admin route
	if (!isAdminRoute) {
		try {
			await import('@/assets/css/fonts.css');
			await import('@/assets/css/custom.css');
			await import('@/assets/css/responsive.css');
		} catch (error) {
			console.error("Error loading CSS dynamically:", error);
		}
	}

	// Admin authentication logic
	const isAdminLoggedIn = store.getters.isLoggedIn;
    const isUserLoggedIn = store.getters.isUserLoggedIn;

	// Admin authentication logic
	if (to.matched.some((route) => route.meta.requiresAdminAuth)) {
		if (!isAdminLoggedIn) {
			return next("/admin/login"); // Redirect to admin login if not logged in
		}
	}

	if (to.path === "/admin/login" && isAdminLoggedIn) {
		return next("/admin/dashboard"); // Redirect to admin dashboard if already logged in
	}

	if (isAdminLoggedIn && to.name !== "Dashboard" && to.path.includes("/admin")) {
		return next(); // Allow navigation if logged in as admin and not going to Dashboard
	}

    if (to.matched.some((route) => route.meta.requiresUserAuth)) {
        if (!isUserLoggedIn) {
			next({
				name: 'SignIn', // Replace with your login page route name
                query: { redirect: to.fullPath.split('?')[0] } // Clean URL for redirect
			});
        } else {
            // Validate the token for tenants
            const isValidToken = await validateToken();
            if (!isValidToken) {
                // If token is invalid, log out the user
                store.commit("LOGOUT_USER"); // Call the Vuex mutation to log out
                localStorage.clear(); // Clear local storage
				return next({
					name: 'SignIn',
					query: { redirect: to.fullPath.split('?')[0] }
				});
            }
			next(); // Proceed if the profile checks pass
        }
    }

    // Prevent navigation to onboarding/authentication pages if homeowner is logged in
    const restrictedPaths = [
        "/auth/sign-up",
        "/auth/sign-in",
        "/auth/forgot-password",
        "/auth/choose-password",
        "/auth/enter-otp",
    ];

    if (isUserLoggedIn && restrictedPaths.includes(to.path)) {
        return next("/"); // Redirect to owner listings if trying to access restricted paths
    }

    next(); // Proceed with navigation if none of the above conditions are met
});

export default router
